.wrapper {
  position: relative;
  height: 100vh;
}

.buttonBox {
  grid-template-columns: 1fr;
  max-width: 100%;
}

.container {
  position: relative;
}

.profile {
  margin-top: 100px;
  padding: 15px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: linear-gradient(270deg, #5f28cd, #191863);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}



.button {
  width: 100%;
  min-height: 50px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  margin: 10px auto;
}

.project {
  background-color: #5f27cd;
  color: white;
}

.project:hover {
  cursor: pointer;
}

.hire {
  background-color: #f368e0;
  color: white;
}

.hire:hover {
  cursor: pointer;
}

@media only screen and (min-width: 560px) {

  .button {
    margin: 0 auto;
  }

  .buttonBox {
    max-width: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin: 0 auto;
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}