.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: 800px;
  margin: 0 auto;
}

.card {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.playerIcon{
  background-color: #140772 !important;
  padding: 15px;
  border-radius: 50%;
  width:150px;
  height: 150px;
}

.icon {
  width: 50px;
  height: 50px;
}

.title {
  margin-bottom: 10px;
  color: #2c2851;
  font-family: "Montserrat", sans-serif;
}

.description {
  color: #85819f;
}

@media only screen and (max-width: 560px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
