.wrapper {
  display: grid;
  gap: 10px;
  max-width: 800px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  align-self: center;
  align-items: center;
}

.card {
  display: grid;
  grid-template-columns: 4fr 1fr;
  border-radius: 10px;
  background-color: white;
  margin: 5px 0;
}
.reverse{
  order: -1;
}
.aboutRow{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.aboutRow h2{
  color: #fff;
  background-color: #5f28cd;
  border-radius: 20px;
}
.playerIcon{
  width: 200px;
}
.info {
  padding: 10px;
  display: grid;
  place-items: center;
}

.icon {
  height: 50px;
  height: 50px;
  background: #191863;
  padding: 10px;
  border-radius: 50%;
}

.title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #2c2851;
  font-family: "Montserrat", sans-serif;
}
.subtitle {
  padding-top: 10px;
  border-top: #f368e0 1px solid;
  color: #5f28cd;
  font-family: "Montserrat", sans-serif;
}
.description {
  color: #85819f;
  margin-bottom: 5px;
}

@media only screen and (max-width: 560px) {
  .card {
    grid-template-columns: 1fr;
  }

  .card > div:first-child {
    grid-row: 2;
  }
}
