/* ScrollToTopButton.css */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #5f28cd;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button.visible {
  opacity: 1;
  z-index: 10000;
}
