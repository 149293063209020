.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  position: fixed;
  width: 100%;
  left: 0;
  background: #5f27cd;
  padding: 20px 10rem;
  top: 0;
  z-index: 10;
}

.brand {
  display: grid;
  grid-template-columns: 30px auto;
  max-width: 300px;
  gap: 10px;
  place-items: center;
}

.brand:hover {
  cursor: pointer;
}

.icon {
  width: 30px;
  height: 30px;
}

.logo {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans;
  color: #f368e0;
}

.toggle {
  display: none;
  cursor: pointer;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 18px;
}

.item {
  color: #f368e0;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .toggle {
    display: block;
    height: 35px;
  }

  .navigation {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    font-weight: bold;
    height: 100vh;
    background-color: #efeff1;
  }

  .item {
    width: 100vw;
  }
  .wrapper{
    padding: 15px;

  }
}
