@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Varela+Round&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Varela Round", sans-serif;
}
.lf-player-container .player-bg{
  position: fixed;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: -1;
}
body {
  background-color: #191863;
}
.link{
  color:white;
}
.title {
  font-family: "Montserrat", sans-serif;
  margin: 40px 0 20px 0;
  color: #5f27cd;
  font-size: 40px;
}

.description {
  margin-bottom: 30px;
  color: #f368e0;
  font-size: 18px;
}

.App {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  overflow: hidden
}


.divider{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}
.divider svg{
  position: relative;
    display: block;
    width: calc(135% + 1.3px);
    height: 180px;
}
.divider .shape-fill {
  fill: #5f27cd;
}
